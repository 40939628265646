import { Board, Controls, Keyboard } from '../components'

function Game() {
  return (
    <main id="game-wrapper">
      <Board />
      <Controls />
      <Keyboard />
    </main>
  )
}

export default Game
